.root {
  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-weight: var(--fontWeightSemiBold);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 12px;
  color: var(--colorWhite);

  padding: 12px;

  height: 24px;
  width: fit-content;
}

.bottomRight {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0;
}

.bottomLeft {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px;
}

.topRight {
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.topLeft {
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.expired {
  background-color: var(--marketplaceColorWarning);
  color: var(--colorWhite);
  border: none;
}

.soon {
  background-color: var(--marketplaceColorAware);
  color: var(--colorBlack);
}

.prolonged,
.customerBuyOut {
  background-color: var(--marketplaceGrey500);
  color: var(--colorWhite);
}

.contractFinalized,
.newLeasingOffering {
  background-color: var(--marketplaceColorAlert);
  color: var(--colorWhite);
}
