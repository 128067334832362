@import '../../styles/customMediaQueries.css';

.root {
  background-color: var(--colorWhite);
  padding: 12px;
  border-radius: var(--borderRadiusSection);
}

.img {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  min-width: 36px;
  object-fit: cover;
  margin-right: 6px;
}

.content {
  display: flex;
  flex-direction: column;
}

.info {
  flex: 1;
  font-weight: var(--fontWeightSemiBold);
}

.heading {
  composes: p from global;
  margin: 0;
}

.location {
  flex: 1;
  color: var(--colorGrey500);
  line-height: 20px;
}
