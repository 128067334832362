.contractLeaseData {
  display: flex;
  flex-direction: column;
}

.contractText {
  font-size: 14px;
}

.semiBold {
  font-weight: var(--fontWeightSemiBold);
}

.outputGrading {
  padding: 2px 4px;
  border-radius: 6px;
  font-weight: var(--fontWeightSemiBold);
  margin-left: 4px;
}

.grade_a {
  background-color: var(--colorSuccess);
  color: var(--colorWhite);
}

.grade_b {
  background-color: var(--colorInfo);
  color: var(--colorWhite);
}

.grade_c {
  background-color: var(--colorAttention);
  color: var(--colorWhite);
}

.grade_d {
  background-color: var(--colorFail);
  color: var(--colorWhite);
}

.productServiced {
  margin-left: 4px;

  & > svg {
    height: 12px;
    width: 12px;
  }
}
