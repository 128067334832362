@import '../../styles/customMediaQueries.css';

.container {
  composes: marketplaceModalBaseStyles from global;
}

.title {
  composes: marketplaceModalTitleStyles from global;
}

.description {
  composes: marketplaceModalParagraphStyles from global;
}

.error {
  composes: marketplaceModalErrorStyles from global;
}

.buttons {
  composes: marketplaceModalBottomWrapper from global;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
}

.confirmButton {
  width: auto;
  white-space: nowrap;
  padding: 4px 16px;

  @media (--viewportSmall) {
    padding: 8px 32px;
  }
}
