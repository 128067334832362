.row {
  display: flex;
  gap: 8px;
}

.label {
  font-weight: var(--fontWeightSemiBold);
}

.value {
  font-weight: var(--fontWeightRegular);
}
