.root {
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  & > span {
    &:first-of-type {
      display: flex;
      margin-right: 12px;
    }

    &:last-of-type {
      font-weight: var(--fontWeightRegular);
      line-height: 22px;
    }
  }
}
