.root {
  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 16px;
  white-space: nowrap;

  border: none;
  padding: 12px;

  height: 32px;
}

.pendingIntake {
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
}

.pendingReview {
  background-color: var(--marketplaceGrey500);
  color: var(--colorWhite);
}

.toBeRefurbished {
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
}

.sellAsIs {
  background-color: var(--marketplaceColorSecondary);
  color: var(--colorWhite);
}

.scrapped {
  background-color: var(--colorBlack);
  color: var(--marketplaceGrey300);
}

.sold {
  background-color: var(--colorBlack);
  color: var(--colorWhite);
}

.pendingSold {
  background-color: var(--marketplaceGrey700);
}

.refurbished {
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
}

.published {
  background-color: var(--marketplaceColorSecondary);
  color: var(--colorWhite);
}

.bottomRight {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 0;
}

.bottomLeft {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 12px;
}

.topRight {
  border-top-left-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.topLeft {
  border-top-left-radius: 0;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
