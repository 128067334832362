@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.accordion {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 0px;
  width: 100%;
  border-bottom: 1px solid var(--colorGrey400);
  opacity: 1;
}

.content {
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.3s, opacity 0.3s, visibility 0.3s linear;

  & div p {
    line-height: 22px;
  }
}

.contentActive {
  visibility: visible;
  opacity: 1;
  max-height: 5000px;
  transition: visibility 0s, opacity 0.3s, max-height 0.6s linear;
}

.withChildren {
  height: auto;
}

.wrapText {
  white-space: pre-wrap;
}
