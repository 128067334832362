@import '../../../styles/customMediaQueries.css';

.title {
  composes: marketplaceModalTitleStyles from global;

  margin-bottom: 36px;
}

.error {
  color: var(--colorFail);
}

.field {
  margin-bottom: 24px;
}

.btns {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-top: 48px;
}

.submit {
  width: auto;
  padding: 12px 20px;
  min-height: auto;
}
