@import '../../styles/customMediaQueries.css';

.root {
  display: block;

  flex-shrink: 0;
  padding: 0;
  height: 100%;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    padding: 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.logo {
  /* If height is increased, change link margins accordingly */
  /* NOTE: extremely wide logos may look bad. This will scale them to container of 24 x 200. */
  height: 24px;
  width: auto;
  max-width: 188px;
  object-fit: contain;
  object-position: center;
  margin: 18px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.skeleton {
  display: inline-block;

  & > span {
    display: inline-block;
    width: 100px;
    height: 24px;
    border-radius: 4px;
    position: relative;
    background: var(--colorGrey200);

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 100%;
      box-shadow: 0 0 80px 20px #fff;
      animation: skeletonSlide 0.6s infinite ease-in-out;
    }
  }
}

@keyframes skeletonSlide {
  from {
    left: -100px;
  }
  to {
    left: calc(100% + 100px);
  }
}
