@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  border: none;
}

.required {
  color: var(--colorFail);
}
