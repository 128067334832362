@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.ctaWrapper {
  display: flex;
  align-items: center;
}

.cta {
  composes: buttonPrimary from global;

  width: auto;
  padding: 12px 24px;
  min-height: auto;
  flex-basis: 200px;
  margin-right: 12px;

  & > input {
    opacity: 0;
    padding: 0;
    height: 0;
    width: 0;
    border: 0;
  }
}

.disabledCta,
.disabledCta:hover {
  box-shadow: none;
  cursor: not-allowed;
  background-color: var(--colorGrey100);
  color: var(--colorGrey700);
}

.download {
  & > span {
    margin-right: 12px;
  }

  & > a {
    text-decoration: underline;
  }
}

.required {
  color: var(--colorFail);
}
