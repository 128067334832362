@import '../../styles/customMediaQueries.css';

.root {
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.copiedWrapper {
  position: relative;
  pointer-events: none;
}

.copied {
  position: absolute;
  top: -100%;
  left: 6px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadow);
  border-radius: var(--borderRadiusMedium);
  padding: 6px 8px;
}
