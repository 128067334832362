@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 12px;

  & button {
    & svg {
      fill: var(--colorWhite);
      stroke: var(--colorWhite);
      height: 18px;
      width: 18px;
      margin-right: 8px;
    }
  }
}

.inline {
  & button {
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.error {
  color: var(--colorFail);
}

.declineNormal {
  composes: buttonSecondary from global;
}

.declineInline {
  color: var(--colorFail);
}
