.root {
}

.selectError {
  border: var(--borderErrorField);
}

.required {
  color: var(--colorFail);
}
