@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: relative;
}

.link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.listView {
  flex-direction: row;

  & .aspectRatioWrapper {
    width: 76px;
    min-width: 76px;
    margin-right: 12px;
    align-self: flex-start;

    & *[class*='ResponsiveImage_noImageIcon'] {
      padding-top: 0;
      width: 30px;
    }
    & *[class*='ResponsiveImage_noImageText'] {
      display: none;
    }

    @media (--viewportSmall) {
      width: 180px;
      min-width: 180px;
      height: 136px;

      & *[class*='ResponsiveImage_noImageIcon'] {
        padding-top: 24px;
        width: 48px;
      }
      & *[class*='ResponsiveImage_noImageText'] {
        display: block;
      }
    }
  }

  & .info {
    padding-top: 0;
    justify-content: space-between;
    flex-grow: 1;

    @media (--viewportMedium) {
      flex-direction: row;
    }
  }

  & .title {
    @media (--viewportMedium) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  & .authorInfo {
    margin-top: auto;
    display: none;
    align-items: center;
    padding-bottom: 0;

    @media (--viewportSmall) {
      display: flex;
    }
  }

  & .location {
    display: none;

    @media (--viewportSmall) {
      display: block;
    }
  }

  & .priceWrapper {
    padding: 6px 8px;
    font-size: 12px;

    @media (--viewportSmall) {
      padding: 6px 16px;
      font-size: 16px;
    }
  }
}

/* ---------- Price classes ---------- */

.priceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: fit-content;
  order: 1;
  margin-left: 8px;
}

.priceOverImage {
  position: absolute;
  top: 0px;
  right: 0px;
}

.priceWrapper {
  background-color: var(--marketplaceColorLight);
  color: var(--colorWhite);
  padding: 6px 16px;
  text-align: center;
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--marketplaceGrey300); /* Loading BG color */

  pointer-events: none;

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.location {
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  z-index: 0;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.avatar {
  margin-right: 12px;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.leaseData {
  margin-top: 6px;
}

.listingCard2ndLife {
  border-radius: 32px;
  background-color: var(--colorWhite);
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.04);

  &:hover {
    cursor: pointer;
    box-shadow: 3px 3px 2px 1px rgba(0, 0, 0, 0.16);
  }

  & > .aspectRatioWrapper {
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;

    img {
      border: none;
      border-radius: 0;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      position: absolute;
      object-fit: contain;
    }

    .listingStatus {
      position: absolute;
      bottom: 8px;
      left: 24px;
    }

    .listingFlag {
      position: absolute;
      bottom: calc(8px + 32px + 6px);
      left: 24px;
    }

    .listViewListingStatus {
      position: absolute;
      bottom: 8px;
      right: 8px;
    }

    .listViewlListingFlag {
      position: absolute;
      bottom: calc(8px + 32px + 6px);
      right: 8px;
    }
  }

  & > .info {
    & > .mainInfo {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      min-height: 170px;
    }

    .title {
      font-size: 18px;
      line-height: 20px;
    }

    .residualValue {
      color: var(--colorGrey400);
      align-self: flex-end;
    }
  }
}

.listingCard2ndLife.listView {
  border-radius: 32px;
  background-color: var(--colorWhite);
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.04);

  &:hover {
    cursor: pointer;
    box-shadow: 3px 3px 2px 1px rgba(0, 0, 0, 0.16);
  }

  & > .aspectRatioWrapper {
    border-radius: 0;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 160px;

    & > div > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      border: none;
      border-radius: 0;
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
      height: auto;
    }
  }

  & > .info {
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > .mainInfo {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      min-height: 170px;
    }

    .title {
      font-size: 18px;
      line-height: 20px;
    }

    .residualValue {
      color: var(--colorGrey400);
      align-self: flex-start;
    }
  }
}

.hide {
  display: none;
}

.leaseActionsWrapper {
  align-self: center;
}

.leaseActions {
  position: relative;
  align-self: center;
  z-index: 2;
  min-width: 180px;
  max-width: 180px;

  button {
    font-size: 14px;
    padding: 8px 16px;
    margin: 0;
    min-height: unset;
  }
}
