.root {
  height: 100%;
  width: 100%;
  border: 0;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
}

.clickedWithMouse {
  &:focus {
    outline: none;
  }
}

/* Default isOpen class - no styles yet */
.isOpen {
}

.icon {
  transition: transform 0.3s;
  margin-left: 6px;
}

.iconArrowAnimation {
  transform: rotate(-180deg);
}
